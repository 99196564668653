import React from "react"

import styled from "@emotion/styled"
import { padding, readableColor } from "polished"

import { bp, bpLg, H3, H4, P, shevy, theme, toEm } from "../components"

export const Title = H3.withComponent("h1")
export const SmallH2 = H4.withComponent("h2")

export const Heading: React.FC<{
  title: React.ReactNode
  subtitle?: React.ReactNode
  children?: never
}> = ({ title, subtitle, ...props }) => {
  return subtitle ? (
    <div {...props}>
      <Title css={{ "&&": { marginBottom: shevy.baseSpacing(0.2) } }}>
        {title}
      </Title>
      <P css={{ "&&": { marginBottom: 0 } }}>{subtitle}</P>
    </div>
  ) : (
    <Title {...props}>{title}</Title>
  )
}

export const Section = styled.section({
  ...padding(toEm(32 + 16), toEm(24)),
  [bp]: {
    ...padding(toEm(64), toEm(32)),
  },
  [bpLg]: {
    ...padding(toEm(64), toEm(128)),
  },
})

export const Ribbon = styled.div<{
  backgroundColor?: string
  fontColor?: string
}>(
  ({
    backgroundColor = theme.colors.green,
    fontColor = theme.colors.white,
  }) => ({
    backgroundColor: backgroundColor,
    color: fontColor ? fontColor : readableColor(backgroundColor),
    ...padding(toEm(32 + 16), toEm(32)),

    [bp]: {
      marginLeft: toEm(32),

      paddingTop: toEm(64),
      paddingLeft: toEm(64),
      paddingBottom: toEm(64),
      paddingRight: toEm(32),

      borderRadius: `${toEm(12)} 0px 0px ${toEm(12)}`,
    },
    [bpLg]: {
      marginLeft: toEm(64),
      paddingRight: toEm(54),
    },
  })
)

export const RibbonTitle = styled.div({
  fontSize: toEm(30),
  fontWeight: 600,
  [bp]: {
    fontSize: "2em",
  },
})
export const RibbonSubTitle = styled.div({ marginTop: shevy.baseSpacing(0.3) })
